<template>
    <div class="side-by-side">
        <template v-if="$slots['left'] || $slots['default']">
            <div class="side-by-side__left-side">
                <slot
                    v-if="$slots['left']"
                    name="left"
                />
                <slot v-else />
            </div>
        </template>

        <template v-if="$slots['right']">
            <div class="side-by-side__right-side">
                <slot name="right" />
            </div>
        </template>
    </div>
</template>

<script lang="ts" setup>

</script>

<style scoped lang="scss">
.side-by-side {
    @media screen and (min-width: 64rem) {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 65rem;
        margin: auto;
        padding: 0 2rem;
        gap: 6rem;
    }

    &__left-side,
    &__right-side {
        flex: 0 1 50%;

        @media screen and (min-width: 64rem) {
            padding: 0;
        }
    }
}
</style>
