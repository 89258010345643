import { createApp, defineComponent } from 'vue/dist/vue.esm-bundler';
import LoginSection from "@modules/Login/LoginSection.vue";
import AuthLayout from '@components/Login/AuthLayout.vue';
import { getQueryParam, redirectWithoutParams } from "@ts/Util/http";
const LoginWith = defineComponent({
    name: 'LoginWith',
    components: {
        AuthLayout,
        LoginSection,
    },
    setup() {
        const onSuccessfulLogin = () => redirectWithoutParams(getQueryParam('redirect') ?? '/');
        const onFailureLogin = (error) => console.error(error);
        return {
            onSuccessfulLogin,
            onFailureLogin
        };
    }
});
createApp(LoginWith).mount('#login-with');
