import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "z-b2 auth-section__footer-container__sign-up-text" };
const _hoisted_2 = ["href"];
import AuthSection from "@components/Login/AuthSection.vue";
import LoginFormHandler from "@components/Login/Form/LoginFormHandler.vue";
import { reactive } from "vue";
import { isWebview } from '@ts/Util/responsiveness';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LoginSection',
    props: {
        recaptchaKey: {
            type: String,
            default: ''
        },
        compact: {
            type: Boolean,
            default: false,
        },
        showSignUpUrl: {
            type: Boolean,
            default: false
        },
        allowsSsoAccountCreation: {
            type: Boolean,
            default: false
        },
        appleCredentials: {
            type: Object,
            required: true
        },
        facebookCredentials: {
            type: Object,
            required: true
        },
        googleCredentials: {
            type: Object,
            required: true
        },
        signUpHref: {
            type: String,
            default: '/user/signup'
        },
        forgotPasswordHref: {
            type: String,
            default: '/user/forgot_password'
        },
        webviewLogin: {
            type: Boolean,
            default: false
        },
        t: {
            type: Object,
            default: () => ({
                title: 'Log in',
                subtitle: 'Log in using one of the options below',
                emailLabel: 'Email',
                passwordLabel: 'Password',
                rememberLabel: 'Remember Me',
                forgotPasswordText: 'Forgot Password?',
                loginViaEmailText: 'Log in via email link',
                loginText: 'Log In',
                useAtLeastTwo: 'Use at least 2 of the following to create a strong password',
                abcCapitalized: 'ABC',
                abcLowerCase: 'abc',
                numeric: '123',
                symbols: '!@%',
                show: 'Show',
                hide: 'Hide',
                newToZumbaText: 'New to zumba?',
                signUpText: 'Sign Up',
            })
        },
    },
    emits: ['successful', 'failure'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const state = reactive({
            error: '',
            disabled: false,
        });
        const disableForm = () => state.disabled = true;
        const enableForm = () => state.disabled = false;
        const beforeLoginSuccessful = (event) => emit('successful', event);
        const beforeLoginFailure = (event) => {
            enableForm();
            state.error = event.error || event.message;
            emit('failure', event);
        };
        const isWebviewBrowser = () => props.webviewLogin && isWebview();
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(AuthSection, {
                "allows-sso-account-creation": props.allowsSsoAccountCreation,
                compact: __props.compact,
                t: props.t,
                "apple-credentials": props.appleCredentials,
                "facebook-credentials": props.facebookCredentials,
                "google-credentials": props.googleCredentials,
                error: state.error,
                "is-webview": isWebviewBrowser(),
                onSuccessful: beforeLoginSuccessful,
                onLoginStarted: disableForm,
                onFailure: beforeLoginFailure
            }, _createSlots({
                form: _withCtx(() => [
                    _createVNode(LoginFormHandler, {
                        "remember-me": "",
                        t: props.t,
                        "recaptcha-key": props.recaptchaKey,
                        "forgot-password-href": props.forgotPasswordHref,
                        disabled: state.disabled,
                        "is-webview": isWebviewBrowser(),
                        onSuccessful: beforeLoginSuccessful,
                        onFailure: _cache[0] || (_cache[0] = (error) => state.error = error),
                        onResetError: _cache[1] || (_cache[1] = () => state.error = '')
                    }, null, 8, ["t", "recaptcha-key", "forgot-password-href", "disabled", "is-webview"])
                ]),
                _: 2
            }, [
                (props.showSignUpUrl)
                    ? {
                        name: "footer",
                        fn: _withCtx(() => [
                            _createElementVNode("p", _hoisted_1, _toDisplayString(props.t.newToZumbaText), 1),
                            _createElementVNode("a", {
                                href: props.signUpHref,
                                class: "z-b2 auth-section__footer-container__sign-up-link"
                            }, _toDisplayString(props.t.signUpText), 9, _hoisted_2)
                        ]),
                        key: "0"
                    }
                    : undefined
            ]), 1032, ["allows-sso-account-creation", "compact", "t", "apple-credentials", "facebook-credentials", "google-credentials", "error", "is-webview"]));
        };
    }
});
